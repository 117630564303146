
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      selectedDeliveryManId: {},
      selectedDeliveryMen: [],
      selectedDayId: "",
      selectedDays: [],
      weekdays: [
        { id: 1, name: "Monday" },
        { id: 2, name: "Tuesday" },
        { id: 3, name: "Wednesday" },
        { id: 4, name: "Thursday" },
        { id: 5, name: "Friday" },
        { id: 6, name: "Saturday" },
        { id: 7, name: "Sunday" },
      ],
      DeliveryMen: [],
      district_id: null,
      AreasDistrictAvailableDays: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchAreasDistrictAvailableDays: null,
      AreasDistrictAvailableDaysSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addDay() {
      const selectedDay = this.weekdays.find(
        (day) => day.id === this.selectedDayId
      );
      if (
        selectedDay &&
        !this.selectedDays.some((day) => day.id === selectedDay.id)
      ) {
        this.selectedDays.push(selectedDay);
        this.selectedDayId = ""; // Reset the select input
      }
    },
    removeDay(day) {
      this.selectedDays = this.selectedDays.filter((d) => d.id !== day.id);
    },
    addDeliveryMan() {
      const selectedDeliveryMan = this.DeliveryMen.find(
        (man) => man.id === this.selectedDeliveryManId
      );
      if (
        selectedDeliveryMan &&
        !this.selectedDeliveryMen.some(
          (man) => man.id === selectedDeliveryMan.id
        )
      ) {
        this.selectedDeliveryMen.push(selectedDeliveryMan);
        this.selectedDeliveryManId = ""; // Reset the select input
      }
    },
    removeDeliveryMan(deliveryMan) {
      this.selectedDeliveryMen = this.selectedDeliveryMen.filter(
        (man) => man.id !== deliveryMan.id
      );
    },

    async submitData() {
      try {
        await this.http.post("areas-district-available-days", {
          days: this.selectedDays,
          district_id: this.district_id,
          deliveryMen: this.selectedDeliveryMen.map((man) => man.id), // Send only IDs
        });

        this.get(1);
        this.selectedDays = [];
        this.selectedDeliveryMen = [];
        console.log("Days submitted successfully");
      } catch (error) {
        console.error("Error submitting days:", error);
      }
    },
    getDeliveryMen() {
      this.http.get("delivery-men").then((res) => {
        this.DeliveryMen = res.data;
      });
    },
    addAreasDistrictAvailableDays() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addAreasDistrictAvailableDays",
        inputs: [
          {
            model: "driver_id",
            type: "select",
            options: (() => {
              return this.DeliveryMen.map((data) => {
                return {
                  value: data.id,
                  label: data?.full_name,
                };
              });
            })(),
            label: "inventory_logs.driver_id",
          },
          {
            model: "available_day",
            type: "text",
            label: "areas_district_available_days.available_day",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.district_id = this.district_id;
              this.http.post("areas-district-available-days", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editAreasDistrictAvailableDays(app) {
      var data = {
        title: "popups.areas_district_available_days",
        inputs: [
          {
            model: "district_id",
            type: "text",
            label: "areas_district_available_days.district_id",
            value: app.district_id,
          },
          {
            model: "available_day",
            type: "text",
            label: "areas_district_available_days.available_day",
            value: app.available_day,
          },
          {
            model: "created_at",
            type: "text",
            label: "areas_district_available_days.created_at",
            value: app.created_at,
          },
          {
            model: "updated_at",
            type: "text",
            label: "areas_district_available_days.updated_at",
            value: app.updated_at,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http
                .put("areas-district-available-days", app.id, obj)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateAreasDistrictAvailableDays(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            model: "district_id",
            type: "text",
            label: "areas_district_available_days.district_id",
          },
          {
            model: "available_day",
            type: "text",
            label: "areas_district_available_days.available_day",
          },
          {
            model: "created_at",
            type: "text",
            label: "areas_district_available_days.created_at",
          },
          {
            model: "updated_at",
            type: "text",
            label: "areas_district_available_days.updated_at",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("areas-district-available-days", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.AreasDistrictAvailableDaysSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("areas-district-available-days/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.AreasDistrictAvailableDays = res.data;
          console.log("######## files", this.AreasDistrictAvailableDays);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.AreasDistrictAvailableDaysSearchMode = false;
      this.get(this.page);
    },

    getAreasDistrictAvailableDays() {
      this.http.get("areas-district-available-days").then((res) => {
        this.AreasDistrictAvailableDays = res.data;
      });
    },
    deleteAreasDistrictAvailableDays(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("areas-district-available-days", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      this.district_id = this.$route.params.id;
      console.log(page);
      this.http
        .post("areas-district-available-days/paginate", {
          limit: this.limit,
          district_id: this.district_id,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.AreasDistrictAvailableDays = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getDeliveryMen();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.areas_district_available_days.text')"
    />
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="selector container mt-5">
            <!-- Selected Days -->
            <div class="selected-days d-flex flex-wrap mb-4">
              <button
                v-for="(day, index) in selectedDays"
                :key="index"
                class="btn day-button shadow-sm"
                @click="removeDay(day)"
              >
                {{ day.name }}
                <span class="btn-close ms-2"></span>
              </button>
            </div>

            <!-- Select Day -->
            <div class="form-group mb-4">
              <select
                v-model="selectedDayId"
                @change="addDay"
                class="form-select"
              >
                <option disabled value="">Select a day</option>
                <option v-for="day in weekdays" :key="day.id" :value="day.id">
                  {{ day.name }}
                </option>
              </select>
            </div>

            <!-- Selected Delivery Men -->
            <div class="selected-delivery-men d-flex flex-wrap mb-4">
              <button
                v-for="(deliveryMan, index) in selectedDeliveryMen"
                :key="index"
                class="btn delivery-man-button shadow-sm"
                @click="removeDeliveryMan(deliveryMan)"
              >
                {{ deliveryMan.full_name }}
                <span class="btn-close ms-2"></span>
              </button>
            </div>

            <!-- Select Delivery Man -->
            <div class="form-group mb-4">
              <select
                v-model="selectedDeliveryManId"
                @change="addDeliveryMan"
                class="form-select"
              >
                <option disabled value="">Select a delivery man</option>
                <option
                  v-for="deliveryMan in DeliveryMen"
                  :key="deliveryMan.id"
                  :value="deliveryMan.id"
                >
                  {{ deliveryMan.full_name }}
                </option>
              </select>
            </div>

            <button class="btn btn-success mt-2 mb-2" @click="submitData">
              Submit Data
            </button>
          </div>
        </div>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("areas_district_available_days.id") }}</th>
             <th scope="col">
            {{ $t("areas_district_available_days.driver_id") }}
          </th>

          <th scope="col">
            {{ $t("areas_district_available_days.available_day") }}
          </th>
          <th scope="col">
            {{ $t("areas_district_available_days.operations") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(app, index) in AreasDistrictAvailableDays"
          :key="app"
          class="text-center"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ app.driver?.full_name }}</td>
          <td>{{ app.available_day }}</td>
          <td colspan="2">
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteAreasDistrictAvailableDays(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
.selected-days,
.selected-delivery-men {
  gap: 0.5rem;
}

.day-button,
.delivery-man-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  background-color: #007bff;
  color: white;
  border: none;
  transition: all 0.3s ease;
}

.day-button:hover,
.delivery-man-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.btn-close {
  font-size: 1rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.btn-close:hover {
  color: #ff4d4d;
}

.form-select {
  border-radius: 0.375rem;
  border-color: #ced4da;
  box-shadow: none;
  transition: all 0.3s ease;
}

.form-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.btn-success {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.btn-success:hover {
  background-color: #218838;
}
</style>




